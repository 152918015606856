import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';

import './passwordReset.scss';
import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import { ReactComponent as BackArrow } from '../../../images/back2.svg';
import { ReactComponent as WarningSVG } from '../../../images/warning_circle_red.svg';
import { NewPasswordInputState } from '../../../types/auth';
import { LocaleType } from '../../../utils/i18n';
import { PASSWORD_ALL_REQUIREMENTS_VALIDATOR } from '../../../utils/regexes';
import { pathToLogin } from '../../../utils/user';
import Loading from '../../global/loading/loading';
import NewPasswordInput from '../newPasswordInput/newPasswordInput';

const PasswordReset: React.FC<{
  token: string;
}> = ({ token }) => {
  const { i18n } = useContext(GlobalContext);
  const currentLocale: LocaleType = useLocaleFromRouter();

  const [newPasswordInput, setNewPasswordInput] =
    useState<NewPasswordInputState>({
      value: '',
      isValid: false,
    });
  const [confirmationInput, setConfirmationInput] =
    useState<NewPasswordInputState>({ value: '', isValid: false });
  const [error, setError] = useState(false);
  const [invalidCodeError, setInvalidCodeError] = useState(false);
  const [resetPwdError, setResetPwdError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const resetAllError = () => {
    setError(false);
    setInvalidCodeError(false);
    setResetPwdError(false);
  };

  useEffect(() => {
    if (newPasswordInput)
      setNewPasswordInput((prevValue) => {
        return {
          ...prevValue,
          isValid: PASSWORD_ALL_REQUIREMENTS_VALIDATOR.test(
            newPasswordInput.value
          ),
        };
      });
  }, [newPasswordInput.value]);

  useEffect(() => {
    if (confirmationInput)
      setConfirmationInput((prevValue) => {
        return {
          ...prevValue,
          isValid: newPasswordInput.value === confirmationInput.value,
        };
      });
  }, [newPasswordInput.value, confirmationInput.value]);

  const checkValidityOnSubmit = (): boolean => {
    return confirmationInput.isValid && newPasswordInput.isValid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    // Already submitting
    if (submitting) {
      return;
    }

    resetAllError();

    if (!checkValidityOnSubmit()) return setError(true);
    if (checkValidityOnSubmit()) {
      try {
        setSubmitting(true);

        axios
          .post(`${process.env.GATSBY_STRAPI_URL}/api/auth/reset-password`, {
            code: token,
            password: newPasswordInput.value,
            passwordConfirmation: newPasswordInput.value,
          })
          .then((response) => {
            if (response.status === 200) {
              resetAllError();
              setSuccess(true);
            }
          })
          .catch((err) => {
            const errorMsg = (
              err.response.data?.error?.message ?? ''
            ).toLowerCase();

            resetAllError();
            if (errorMsg.includes('incorrect code')) {
              setInvalidCodeError(true);
            } else {
              setResetPwdError(true);
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      } catch (err) {
        resetAllError();
        setResetPwdError(true);
        setSubmitting(false);
      }
    }
  };

  let forgotPasswordSlug = i18n?.t('forgot.password.slug');
  forgotPasswordSlug = forgotPasswordSlug
    ? forgotPasswordSlug
    : 'forgot-password';

  return (
    <div className="auth-content-wrapper">
      <div className="auth-form-wrapper password-reset-wrapper">
        <div className="back-to-login">
          <a href={pathToLogin(currentLocale, i18n)}>
            <div className="arrow-container">
              <BackArrow />
            </div>
            <span>{i18n?.t('return_to_login')}</span>
          </a>
        </div>
        <h2 className="auth-title">{i18n?.t('reset.password.title')}</h2>
        {success && <p>{i18n?.t('reset.password.success')}</p>}
        {!success && invalidCodeError && (
          <>
            <p>{i18n?.t('reset.password.invalid')}</p>
            <p>
              <a
                className="auth-link"
                href={`/${currentLocale}/${forgotPasswordSlug}`}
              >
                {i18n?.t('forgot_your_password')}
              </a>
            </p>
          </>
        )}
        {!success && !invalidCodeError && (
          <>
            <form className="password-reset-form" onSubmit={handleSubmit}>
              <NewPasswordInput
                setNewPasswordState={setNewPasswordInput}
                newPasswordState={newPasswordInput}
                label={i18n?.t('form.labels.new_password') ?? 'New password'}
                type="new"
              />
              <NewPasswordInput
                setNewPasswordState={setConfirmationInput}
                newPasswordState={confirmationInput}
                label={
                  i18n?.t('form.labels.confirm_password') ?? 'Confirm password'
                }
                type="confirmation"
              />
              <button className="submit-button" type="submit">
                {submitting && <Loading type="btn" />}
                {!submitting && i18n?.t('validate')}
              </button>
            </form>
            {error && (
              <div className="error-container">
                <div className="icon-container">
                  <WarningSVG />
                </div>
                <p>{i18n?.t('form.errors.passwords')}</p>
              </div>
            )}
            {resetPwdError && (
              <div className="error-container">
                <div className="icon-container">
                  <WarningSVG />
                </div>
                <p>{i18n?.t('form.errors.reset_password')}</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PasswordReset;
